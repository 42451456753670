html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-layout {
  min-width: 0;
  background: transparent;
}

.ant-layout-sider-children {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.ant-layout-header {
  padding: 0 !important;
  line-height: normal;
  height: auto;
  background: transparent;
}

.ant-layout-footer {
  padding: 0 !important;
  background: transparent;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial !important;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-tabs-nav .ant-tabs-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1.33rem !important;
}
.ant-tabs-nav .ant-tabs-tab:last-child, .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-right: 0 !important;
}

.vb-tabs,
.vb-tabs-bold,
.vb-tabs-pills {
  overflow: visible !important;
}
.vb-tabs .ant-tabs-top-bar,
.vb-tabs-bold .ant-tabs-top-bar,
.vb-tabs-pills .ant-tabs-top-bar {
  margin-bottom: 0;
  border-bottom: none;
  height: 100%;
}
.vb-tabs .ant-tabs-nav-container,
.vb-tabs .ant-tabs-nav-wrap,
.vb-tabs .ant-tabs-nav-scroll,
.vb-tabs-bold .ant-tabs-nav-container,
.vb-tabs-bold .ant-tabs-nav-wrap,
.vb-tabs-bold .ant-tabs-nav-scroll,
.vb-tabs-pills .ant-tabs-nav-container,
.vb-tabs-pills .ant-tabs-nav-wrap,
.vb-tabs-pills .ant-tabs-nav-scroll {
  height: 100%;
  overflow: visible !important;
}
.vb-tabs .ant-tabs-nav,
.vb-tabs-bold .ant-tabs-nav,
.vb-tabs-pills .ant-tabs-nav {
  height: 100%;
}
.vb-tabs .ant-tabs-nav:before,
.vb-tabs-bold .ant-tabs-nav:before,
.vb-tabs-pills .ant-tabs-nav:before {
  bottom: -1px !important;
}
.vb-tabs .ant-tabs-nav > div,
.vb-tabs-bold .ant-tabs-nav > div,
.vb-tabs-pills .ant-tabs-nav > div {
  height: 100%;
}
.vb-tabs .ant-tabs-nav .ant-tabs-ink-bar,
.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar,
.vb-tabs-pills .ant-tabs-nav .ant-tabs-ink-bar {
  height: 1px;
  bottom: -1px !important;
}
.vb-tabs .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"],
.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"],
.vb-tabs-pills .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"] {
  width: 100% !important;
}
.vb-tabs .ant-tabs-tab-active .nav-link,
.vb-tabs-bold .ant-tabs-tab-active .nav-link,
.vb-tabs-pills .ant-tabs-tab-active .nav-link {
  background-color: #f7c025;
  color: #fff;
  border-radius: 0.25rem;
}

.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar {
  height: 7px !important;
  bottom: -4px !important;
  border-radius: 7px !important;
}

.vb-tabs-pills .ant-tabs-ink-bar {
  display: none !important;
}
.vb-tabs-pills .ant-tabs-nav .ant-tabs-tab {
  padding-left: 0;
  padding-right: 0;
}

.vb-tabs-bordered .ant-tabs-nav-list {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.vb-tabs-bordered.vb-tabs-bold .ant-tabs-top-bar {
  border-bottom: 1px solid #e4e9f0;
}

[data-vb-theme=dark] .ant-tabs .ant-tabs-card-bar .ant-tabs-tab {
  background: transparent;
}
[data-vb-theme=dark] .ant-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #232135;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-notification {
  max-width: calc(100vw - 48px);
}

[data-vb-theme=dark] .ant-notification-notice-message {
  color: #fff;
}
[data-vb-theme=dark] .ant-notification-close-icon svg {
  fill: #fff;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-checkbox-inner {
  background-color: #5f6294;
}
[data-vb-theme=dark] .ant-checkbox-checked .ant-checkbox-inner {
  background: #f7c025;
}
[data-vb-theme=dark] .ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #232135 !important;
  opacity: 0.3;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-tag {
  border-color: transparent;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 !important;
  list-style: none !important;
  padding: 0 !important;
}

[data-vb-theme=dark] .ant-dropdown-menu-item-group-title {
  color: #7575a3 !important;
}
[data-vb-theme=dark] .ant-select-dropdown-menu-item-selected {
  color: #000;
  background: #7275a5;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-radio-disabled .ant-radio-inner {
  border-color: #232135 !important;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper {
  color: #000;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked {
  color: #fff;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover, [data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked:active {
  color: #fff;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-btn:hover, .ant-btn:active, .ant-btn:focus {
  border-color: #42baf9;
  color: #42baf9;
}
.ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
}
.ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background: #42baf9 !important;
  border-color: #42baf9 !important;
  color: #fff;
}
.ant-btn-danger:hover, .ant-btn-danger:active, .ant-btn-danger:focus {
  background: #f63a45;
  border-color: #f63a45;
  color: #fff;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-danger[disabled], .ant-btn-danger[disabled]:hover {
  background: #e4e9f0 !important;
  border-color: #e4e9f0 !important;
}

[data-vb-theme=dark] .ant-btn-primary:hover, [data-vb-theme=dark] .ant-btn-primary:active, [data-vb-theme=dark] .ant-btn-primary:focus {
  background: #42baf9 !important;
  border-color: #42baf9 !important;
  color: #fff;
}
[data-vb-theme=dark] .ant-btn-danger:hover, [data-vb-theme=dark] .ant-btn-danger:active, [data-vb-theme=dark] .ant-btn-danger:focus {
  background: #f7535c;
  border-color: #f7535c;
  color: #fff;
}
[data-vb-theme=dark] .ant-btn-primary[disabled], [data-vb-theme=dark] .ant-btn-primary[disabled]:hover, [data-vb-theme=dark] .ant-btn-danger[disabled], [data-vb-theme=dark] .ant-btn-danger[disabled]:hover {
  background: #1d1b2c !important;
  border-color: #232135 !important;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-empty svg * {
  fill: #a1a1c2;
  stroke: #5f6294;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-select-open .ant-select-selection {
  border-color: #f7c025;
}
.ant-select-selection:hover {
  border-color: #f7c025;
}

[data-vb-theme=dark] .ant-select-multiple .ant-select-selection-item {
  color: #aeaee0;
  background: #f7c025;
}
[data-vb-theme=dark] .ant-select-multiple .ant-select-selection-item-remove {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-select-item-option-active:not(.ant-select-item-option-disabled), [data-vb-theme=dark] .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #aeaee0;
  background: #f7c025;
}
[data-vb-theme=dark] .ant-select-selection--multiple .ant-select-selection__choice {
  color: #aeaee0;
  background: #f7c025;
}
[data-vb-theme=dark] .ant-select-selection--multiple .ant-select-selection__choice-remove {
  color: #4f4f7a;
}
[data-vb-theme=dark] .ant-select-dropdown-menu-item-selected {
  color: #aeaee0;
  background: #f7c025;
}
[data-vb-theme=dark] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  background: #141322;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-modal-mask {
  background: rgba(20, 19, 34, 0.7) !important;
}
.ant-modal-header {
  border-color: transparent;
}
.ant-modal-footer {
  border-color: transparent;
}
.ant-modal-content {
  box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
}

[data-vb-theme=dark] .ant-modal-mask {
  background: rgba(20, 19, 34, 0.84);
}
[data-vb-theme=dark] .ant-modal-close-x {
  color: #a1a1c2;
}
[data-vb-theme=dark] .ant-modal-close-x:hover {
  color: #d9dee9;
}
[data-vb-theme=dark] .ant-modal-content {
  background: #131020;
}
[data-vb-theme=dark] .ant-modal-header {
  background: #131020;
  border-color: #131020;
}
[data-vb-theme=dark] .ant-modal-footer {
  border-color: #131020;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-fullcalendar-content ul {
  padding-left: 0;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #f9f9f9;
}

[data-vb-theme=dark] .ant-calendar-range-picker-separator, [data-vb-theme=dark] .ant-calendar-range-middle {
  color: #a1a1c2;
}
[data-vb-theme=dark] .ant-calendar-active-week {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-calendar-active-week:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #141322;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-progress-inner {
  background: #232135;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-upload {
  background: #232135;
}
[data-vb-theme=dark] .ant-upload.ant-upload-drag {
  background: #232135;
}
[data-vb-theme=dark] .ant-upload .ant-upload-text {
  color: #aeaee0 !important;
}
[data-vb-theme=dark] .ant-upload .ant-upload-hint {
  color: #4f4f7a !important;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-slider-rail {
  background: #232135;
}
[data-vb-theme=dark] .ant-slider-track {
  background: #f7c025;
}
[data-vb-theme=dark] .ant-slider-dot {
  border-color: #4f4f7a;
}
[data-vb-theme=dark] .ant-slider-dot-active {
  border-color: #f7c025;
}
[data-vb-theme=dark] .ant-slider:hover .ant-slider-rail {
  background: #4f4f7a;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-collapse {
  background: #f9f9f9 !important;
}
.ant-collapse-content {
  background: none;
}
.ant-collapse.ant-collapse-borderless .ant-collapse-item:last-child {
  border-bottom: 0 !important;
}
.ant-collapse.ant-collapse-borderless .ant-collapse-content {
  background: #fff !important;
}

[data-vb-theme=dark] .ant-collapse {
  background: transparent !important;
}
[data-vb-theme=dark] .ant-collapse-content {
  background: none;
}
[data-vb-theme=dark] .ant-collapse.ant-collapse-borderless .ant-collapse-content {
  background: transparent !important;
}
[data-vb-theme=dark] .ant-collapse-header {
  background: #141322;
  color: #aeaee0 !important;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-card {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-card-meta-description {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-card-actions {
  background: #0c0c1b;
}
[data-vb-theme=dark] .ant-card-actions > li .anticon {
  color: #aeaee0;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-cascader-menu-item-active {
  background: #f7c025;
}
[data-vb-theme=dark] .ant-cascader-menu-item-active:hover {
  background: #f7c025;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-input {
  color: #4d4d4d !important;
}
.ant-input:hover, .ant-input:focus, .ant-input:active {
  border-color: #f7c025;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled), .ant-input-affix-wrapper:focus .ant-input:not(.ant-input-disabled), .ant-input-affix-wrapper:active .ant-input:not(.ant-input-disabled) {
  border-color: #f7c025;
}

[data-vb-theme=dark] .ant-input-number-handler-wrap {
  background: #4f4f7a;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-mentions textarea {
  background: #232135;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-time-picker-panel-input {
  background: transparent;
}
[data-vb-theme=dark] .ant-time-picker-panel-select-option-selected {
  background: #f7c025;
}
[data-vb-theme=dark] .ant-time-picker-panel-select-option-selected:hover {
  background: #f7c025;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-skeleton {
  opacity: 0.1;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-breadcrumb-link, [data-vb-theme=dark] .ant-breadcrumb-link a {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-breadcrumb-separator {
  color: #4f4f7a;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

[data-vb-theme=dark] .ant-menu-item-group-title {
  color: #7575a3;
}
[data-vb-theme=dark] .ant-menu-item-disabled {
  color: #4f4f7a !important;
}

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: #141322 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: #141322 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

/* LOGIN */
.boxContainer {
  max-width: 30rem;
  min-width: 30rem;
  padding: 2.66rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .boxContainer {
    min-width: 25.33rem;
    padding: 2.66rem;
  }
}

.ant-btn-primary {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}
.ant-btn-primary.btn-selected, .ant-btn-primary:hover {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}

[button-theme=inverter].ant-btn-primary {
  background: #f7c025 !important;
  border-color: #f7c025 !important;
  color: #4d4d4d;
}
[button-theme=inverter].ant-btn-primary.btn-selected, [button-theme=inverter].ant-btn-primary:hover {
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
  color: #fff;
}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}
.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap-message-error h2 {
  font-weight: bold;
  text-align: center;
}

[nz-menu-item] a {
  width: 100%;
}

.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.wrap-card::-webkit-scrollbar {
  border-radius: 2px;
  width: 4px;
}
.wrap-card::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
}
.wrap-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #888;
}
.wrap-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}

.text-date {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33rem;
}

.ant-table {
  background: transparent !important;
}

[data-vb-theme=dark] .ant-table-column-has-sorters:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-table-column-sort {
  background: #141322 !important;
}