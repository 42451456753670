// Import mixins
@import '../../../../../mixins';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme='dark'] {
  .ant-mentions {
    textarea {
      background: $dark-gray-4;
    }
  }
}
