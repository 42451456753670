// Import mixins
@import '../../../../../mixins';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme='dark'] {
  .ant-menu {
    &-item {
      &-group-title {
        color: $dark-gray-2;
      }
      &-disabled {
        color: $dark-gray-3 !important;
      }
    }
  }
}
