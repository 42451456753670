// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates
@import 'assets/css/mixins.scss'; // import KIT mixins
$text: #000;
$gray-1: #f9f9f9;
$text-color: $text !important;

html {
  --antd-wave-shadow-color: $wd-gray-3;
}

.loader-show {
  height: 50vh;
  background: rgba(0, 0, 0, 0.05);
}

.ant-modal-title {
  color: $black !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.ant-form-item-label > label {
  color: $black !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder,
.ant-picker-input input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

nz-tree {
  max-height: 20rem !important;
}

// $success: green;
//////////////////////////////////////////////////////////////////////////////////////////

/* LOGIN */

.boxContainer {
  max-width: rem(450);
  min-width: rem(450);
  padding: rem(40);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  @media (max-width: $md-max-width) {
    min-width: rem(380);
    padding: rem(40);
  }
}

.ant-btn-primary {
  background: $wd-gray-1 !important;
  border-color: $wd-gray-1 !important;
  color: $white;

  &.btn-selected,
  &:hover {
    background: $wd-yellow !important;
    border-color: $wd-yellow !important;
    color: $wd-gray-1;
  }
}

[button-theme='inverter'].ant-btn-primary {
  background: $wd-yellow !important;
  border-color: $wd-yellow !important;
  color: $wd-gray-1;

  &.btn-selected,
  &:hover {
    background: $wd-gray-1 !important;
    border-color: $wd-gray-1 !important;
    color: $white;
  }
}

//.ant-btn-primary:focus {
//  background: $primary !important;
//  border-color: $primary !important;
//  color: $white;
//}

.ant-layout-sider-trigger {
  background: #f2f4f8 !important;
}

.ant-cascader-menus {
  max-width: 1090px;
  overflow: auto;
}

.ant-cascader-menu::-webkit-scrollbar,
.ant-cascader-menus::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ant-cascader-menu::-webkit-scrollbar:vertical,
.ant-cascader-menus::-webkit-scrollbar:vertical {
  width: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-button:increment,
.ant-cascader-menu::-webkit-scrollbar-button,
.ant-cascader-menus::-webkit-scrollbar-button:increment,
.ant-cascader-menus::-webkit-scrollbar-button {
  display: none;
}

.ant-cascader-menu::-webkit-scrollbar:horizontal,
.ant-cascader-menus::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ant-cascader-menu::-webkit-scrollbar-thumb,
.ant-cascader-menus::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ant-cascader-menu::-webkit-scrollbar-track,
.ant-cascader-menus::-webkit-scrollbar-track {
  border-radius: 10px;
}

//Pantallas grandes (desktops de menos de 1200px)
@media (max-width: 1199.98px) {
  .ant-cascader-menus {
    max-width: 890px;
    overflow: auto;
  }
}

//Pantallas medianas (tablets de menos de 992px)
@media (max-width: 991.98px) {
  .ant-cascader-menus {
    max-width: 630px;
    overflow: auto;
  }
}

//Pantallas pequeñas (móviles en landscape de menos de 768px)
@media (max-width: 767.98px) {
  .ant-cascader-menus {
    max-width: 560px;
    overflow: auto;
  }
}

//Pantallas muy pequeñas (móviles en portrait de menos de 576px)
@media (max-width: 575.98px) {
  .ant-cascader-menus {
    max-width: 340px;
    overflow: auto;
  }
}

.section-questions.ant-form-item-label > label {
  height: auto !important;
}

.wrap-message-error {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: bold;
    text-align: center;
  }
}

[nz-menu-item] {
  a {
    width: 100%;
  }
}
 
.wrap-card {
  height: calc(100vh - 300px);
  position: relative;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    border-radius: 2px;
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.text-title {
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(30);
}

.text-date {
  font-size: rem(18);
  font-weight: 500;
  line-height: rem(20);
}
